export const DEFAULT_AUTH_FLOW = 'auth-code';
export const DEFAULT_UX_MODE = 'popup';
export const DEFAULT_SCOPE = 'profile';
export const ICON_COLOR = 'interactive-white';
export const BUTTON_TYPE = 'button';
export const BUTTON_VARIANT = 'secondary';
export const BUTTON_SIZE = 'large';
export const BUTTON_MESSAGE = 'Sign in with Google';
export const LOGIN_BUTTON_MESSAGE = 'Sign in';
export const USERNAME_FIELD = 'username-field';
export const USERNAME_LABEL = 'Email';
export const USERNAME_PLACEHOLDER = 'Email';
export const PASSWORD_FIELD = 'password-field';
export const PASSWORD_LABEL = 'Password';
export const PASSWORD_PLACEHOLDER = 'Password';
export const LOGIN_ERROR = 'Incorrect Username or Password';
