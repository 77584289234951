import { useLazyQuery } from '@apollo/client';
import {
  LOGIN,
  REFRESH_TOKEN,
  VERIFY_TOKEN,
} from 'graphql/authentication/queries.gql';

import { createContext, useContext, useEffect, useState } from 'react';
import { type AuthProviderProps, type IAuthContext } from './AuthContext.types';
import { isValidToken } from 'utils/utilities';
import {
  DEFAULT_AUTH_TOKEN_KEY,
  DEFAULT_CURRENT_USER_KEY,
  DEFAULT_NO_TOKEN_FALLBACK,
  // HOME_PAGE_ROUTE,
  LOGIN_PAGE_ROUTE,
  TOKEN_REFRESH_INTERVAL,
} from 'utils/constants';
import { type User, type AuthenticationResponse } from 'utils/dataTypes';
import useLocalStorage from 'hooks/useLocalStorage';

/**
 * Context for managing user authentication.
 *
 * @constant {context} AuthContext
 */

const AuthContext = createContext<IAuthContext>({
  authenticate: () => {
    return false;
  },
  setAuthentication: () => {},
  login: async () => {},
  logout: () => {},
  currentUser: undefined,
  authError: undefined,
});

/**
 * Custom hook to access the authentication context.
 *
 * @returns {IAuthContext} The authentication context.
 */

export const useAuth = (): IAuthContext => {
  return useContext(AuthContext);
};

/**
 * Provider component that wraps the application with the authentication context.
 *
 * @param {AuthProviderProps} props - The component's properties.
 * @returns {JSX.Element} The AuthProvider component.
 */

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useLocalStorage<User | null>(
    DEFAULT_CURRENT_USER_KEY,
    null
  );
  const [authentication, setAuthentication] = useLocalStorage<string | null>(
    DEFAULT_AUTH_TOKEN_KEY,
    null
  );

  const [authError, setAuthError] = useState<string | undefined>(undefined);
  const [loginRequest, { error: loginError }] = useLazyQuery(LOGIN, {
    onCompleted: (data) => {
      setAuthentication(fetchToken(data.login));
      setCurrentUser(fetchUser(data.login));
    },
  });

  const [refreshTokenRequest, { error: authenticationError }] = useLazyQuery(
    REFRESH_TOKEN,
    {
      onCompleted: (data) => {
        setAuthentication(fetchToken(data.getRefreshTokens));
        setCurrentUser(fetchUser(data.getRefreshTokens));
      },
      fetchPolicy: 'network-only',
    }
  );
  const [verifyTokenRequest] = useLazyQuery(VERIFY_TOKEN);

  useEffect(() => {
    const refreshAccessToken = (): void => {
      void refreshTokenRequest();
    };

    const intervalId = setInterval(refreshAccessToken, TOKEN_REFRESH_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, [authentication, refreshTokenRequest]);

  useEffect(() => {
    if (loginError !== undefined || authenticationError !== undefined) {
      setAuthError('Error while trying to authenticate user');
    }
  }, [loginError, authenticationError]);

  /**
   * Initiate the login process by requesting the refreshToken from our API.
   *
   * @param {string} accessCode - The user's access code.
   * @param {string} username - Username to login.
   * @param {string} password - Password to login.
   */
  const login = async (
    accessCode?: string,
    username?: string,
    password?: string
  ): Promise<void> => {
    await loginRequest({ variables: { accessCode, username, password } });
  };

  /**
   * Authenticate the user using the authentication token.
   *
   * @returns {boolean} True if the token is valid, otherwise false.
   */
  const authenticate = (): boolean => {
    let authenticated: boolean = false;
    void verifyTokenRequest({
      onCompleted: (data) => {
        authenticated = isValidToken(data);
      },
      onError: () => {
        authenticated = false;
      },
    });
    return authenticated;
  };

  /**
   * Log out the user and remove it's credentials
   *
   */
  const logout = (): void => {
    localStorage.removeItem(DEFAULT_AUTH_TOKEN_KEY);
    localStorage.removeItem(DEFAULT_CURRENT_USER_KEY);
    window.location.replace(LOGIN_PAGE_ROUTE);
  };

  /**
   * Verify the user's authentication token.
   *
   * @returns {string} `no-token` if the token is invalid, otherwise a valid refresh token.
   */
  const fetchToken = (data: AuthenticationResponse): string => {
    try {
      return data.token.id;
    } catch (e) {
      setAuthError('Error while trying to authenticate user');
    }
    return DEFAULT_NO_TOKEN_FALLBACK;
  };

  /**
   * Fetch the current user from the login response in JSON format
   *
   * @returns {string} `no-user` if there's an error retrieving the token, otherwise the current user.
   */
  const fetchUser = (data: AuthenticationResponse): User | null => {
    try {
      return data.user;
    } catch (e) {
      setAuthError('Error while trying to fetch authenticated user');
    }
    return null;
  };

  return (
    <AuthContext.Provider
      value={{
        authenticate,
        setAuthentication,
        login,
        logout,
        authError,
        currentUser,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
