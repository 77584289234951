// import { useGoogleLogin, type CodeResponse } from '@react-oauth/google';
import ButtonLink from 'components/molecules/ButtonLink';
import { useAuth } from 'context/Authentication/AuthContext';
import { type LoginPageProps } from './LoginPage.types';
import styles from './loginPage.module.scss';
import { TOAST_TYPE } from 'components/molecules/Toast/Toast/toast.constants';
import { useToast } from 'hooks/useToast';
import { useEffect, useState } from 'react';
import kg_logo from 'assets/svgs/konrad-logo.svg';

import {
  // DEFAULT_AUTH_FLOW,
  // DEFAULT_SCOPE,
  // DEFAULT_UX_MODE,
  ICON_COLOR,
  BUTTON_TYPE,
  BUTTON_VARIANT,
  BUTTON_SIZE,
  // BUTTON_MESSAGE,
  LOGIN_BUTTON_MESSAGE,
  USERNAME_FIELD,
  USERNAME_LABEL,
  USERNAME_PLACEHOLDER,
  PASSWORD_FIELD,
  PASSWORD_LABEL,
  PASSWORD_PLACEHOLDER,
  LOGIN_ERROR,
} from './LoginPage.constants';
import { HOME_PAGE_ROUTE } from 'utils/constants';
import { showToast } from 'utils/utilities';
import Icon from 'components/atoms/Icon';
import Input from 'components/molecules/Input';
import ErrorMessage from 'components/molecules/ErrorMessage';

/**
 * Page for login.
 *
 * @component
 * @returns {JSX.Element} The login page component.
 */

const LoginPage: React.FC<LoginPageProps> = () => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [hasLoginError, setHasLoginError] = useState(false);

  const { login, authError } = useAuth();

  const toast = useToast();

  /**
   * Handles successful login.
   *
   * @param {Object} credentialResponse - The response containing credentials.
   * @param {string} credentialResponse.code - The access token code.
   * @returns {void}
   */

  // const handleLogin = (
  //   credentialResponse: Omit<
  //     CodeResponse,
  //     'error' | 'error_description' | 'error_uri'
  //   >
  // ): void => {
  //   const accessToken = credentialResponse.code;
  //   void login(accessToken).then(() => {
  //     // Instead of using the standard react-router navigation, we're using window.location.replace
  //     // to handle the post-login redirection. This approach is chosen due to the specific nature
  //     // of the login page and its requirements.
  //     //
  //     // The reason behind this exception include:
  //     // - Ensuring a reliable and clean redirection after login.
  //     // - Avoiding potential issues or conflicts with the React Router history stack.
  //     // - Meeting specific security or authentication requirements.
  //     // - Achieving a seamless user experience during login.
  //     if (authError === undefined) {
  //       window.location.replace(HOME_PAGE_ROUTE);
  //     }
  //   });
  // };

  /**
   * Handles login errors.
   *
   * @param {Object} errorResponse - The response containing error details.
   * @returns {void}
   */
  // const handleError = (
  //   errorResponse: Pick<
  //     CodeResponse,
  //     'error' | 'error_description' | 'error_uri'
  //   >
  // ): void => {};

  // const googleLoginCall = useGoogleLogin({
  //   onSuccess: handleLogin,
  //   onError: handleError,
  //   flow: DEFAULT_AUTH_FLOW,
  //   ux_mode: DEFAULT_UX_MODE,
  //   redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT_URI,
  //   scope: DEFAULT_SCOPE,
  // });

  const loginCall = (): void => {
    void login('', username, password).then(() => {
      // Instead of using the standard react-router navigation, we're using window.location.replace
      // to handle the post-login redirection. This approach is chosen due to the specific nature
      // of the login page and its requirements.
      //
      // The reason behind this exception include:
      // - Ensuring a reliable and clean redirection after login.
      // - Avoiding potential issues or conflicts with the React Router history stack.
      // - Meeting specific security or authentication requirements.
      // - Achieving a seamless user experience during login.
      if (authError === undefined) {
        window.location.replace(HOME_PAGE_ROUTE);
      } else {
        setHasLoginError(true);
      }
    });
  };

  const onChangeUsername = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setUsername(event.target.value);
  };

  const onChangePassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setPassword(event.target.value);
  };

  /**
   * Displays an error toast message if an authentication error happens.
   *
   * @param {string} message - The error message to display.
   * @returns {void}
   */

  useEffect(() => {
    if (authError !== undefined) {
      setHasLoginError(true);
      showToast(authError, toast, TOAST_TYPE.ERROR);
    }
  }, [authError, toast]);

  return (
    <div className={styles.login__container}>
      <Icon
        className={styles.login__container__icon}
        src={kg_logo}
        aria-hidden={true}
        disabled
        color={ICON_COLOR}
      />
      <Input
        id={USERNAME_FIELD}
        name={USERNAME_FIELD}
        label={USERNAME_LABEL}
        placeholder={USERNAME_PLACEHOLDER}
        value={username}
        onChange={onChangeUsername}
        className={styles.login__container__input}
      />
      <Input
        id={PASSWORD_FIELD}
        name={PASSWORD_FIELD}
        label={PASSWORD_LABEL}
        type="password"
        placeholder={PASSWORD_PLACEHOLDER}
        value={password}
        onChange={onChangePassword}
        className={styles.login__container__input}
      />
      {hasLoginError && (
        <ErrorMessage
          error={LOGIN_ERROR}
          className={styles.login__container__error_message}
        />
      )}
      <ButtonLink
        as={BUTTON_TYPE}
        variant={BUTTON_VARIANT}
        size={BUTTON_SIZE}
        onClick={() => {
          loginCall();
        }}>
        {LOGIN_BUTTON_MESSAGE}
      </ButtonLink>

      {/* Commented button for Google Oauth, Backend was changed to fit new auth methods
      <ButtonLink
        as={BUTTON_TYPE}
        variant={BUTTON_VARIANT}
        size={BUTTON_SIZE}
        onClick={() => {
          googleLoginCall();
        }}>
        {BUTTON_MESSAGE}
      </ButtonLink> */}
    </div>
  );
};

export default LoginPage;
